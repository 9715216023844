import React from "react";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import Social from "../../social/Social";

const menuContent = [
  {
    name: "Success stories",
    routerPath: "/coming-soon",
    activeClass: "",
    menuClass: "",
    dropDownItems: [],
  },
  {
    name: "Contact",
    activeClass: "",
    menuClass: "two-columns",
    routerPath: "/contact",
    dropDownItems: [],
  },
];

const OLDmenuContent = [
  {
    name: "Services",
    activeClass: "sf-with-ul",
    menuClass: "two-columns current-menu-item",
    routerPath: "/coming-soon",
    dropDownItems: [],
  },
  {
    name: "Success stories",
    routerPath: "/coming-soon",
    activeClass: "",
    menuClass: "",
    dropDownItems: [],
  },
  {
    name: "Latest work",
    routerPath: "/coming-soon",
    activeClass: "",
    menuClass: "",
    dropDownItems: [],
  },
  {
    name: "Media",
    activeClass: "",
    menuClass: "two-columns",
    routerPath: "/coming-soon",
    dropDownItems: [],
  },
  {
    name: "Contact",
    activeClass: "",
    menuClass: "two-columns",
    routerPath: "/contact",
    dropDownItems: [],
  },
];

const MobileMenu = () => {
  return (
    <>
      <div className="ptf-offcanvas-menu__navigation">
        <ProSidebar>
          <SidebarContent>
            <Menu className="sidebar-menu_wrapper">
              {menuContent.map((item, i) => (
                <MenuItem key={i}>
                  <Link to={item.routerPath}>{item.name}</Link>
                </MenuItem>
              ))}
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
      {/* End .ptf-offcanvas-menu__navigation */}

      <div className="ptf-offcanvas-menu__footer">
        <p className="ptf-offcanvas-menu__copyright">
          @{new Date().getFullYear()} <span>Fuzzer</span>. All Rights Reserved.{" "}
          <br />
          {/* Development by{" "}
          <span>
            <a
              href="https://themeforest.net/user/ib-themes"
              target="_blank"
              rel="noopener noreferrer"
            >
              ib-themes
            </a>
          </span>
          . */}
        </p>
        <Social />
      </div>
      {/* End .ptf-offcanvas-menu__footer */}
    </>
  );
};

export default MobileMenu;
